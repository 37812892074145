/* General styles */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
}

.container {
  width: 90%;
  margin: 0 auto;
}

h1, h2, h3, p {
  margin: 0.5em 0;
}

/* Header */
.header {
  background-color: #333;
  padding: 1em 0;
  color: white;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo h1 {
  font-size: 2em;
}

.nav ul {
  list-style: none;
  display: flex;
  gap: 1.5em;
}

.nav a {
  color: white;
  text-decoration: none;
  font-size: 1.2em;
}

/* Hero Section */
.hero {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6em 0;
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
}

.hero-content {
  max-width: 50%;
}

.cta-btn {
  background-color: white;
  color: #007bff;
  padding: 0.7em 1.5em;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  margin-top: 1em;
}

.cta-btn:hover {
  background-color: #007bff;
  color: white;
}

/* Services Section */
.services {
  padding: 3em 0;
  background-color: #f5f5f5;
  text-align: center;
}

.service-cards {
  display: flex;
  justify-content: space-around;
  gap: 1em;
}

.service-card {
  background-color: white;
  padding: 2em;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-icon {
  font-size: 2em;
  color: #007bff;
}

/* About Us Section */
.about {
  padding: 3em 0;
  background-color: white;
  text-align: center;
}

/* Contact Us Section */
.contact {
  padding: 3em 0;
  background-color: #f5f5f5;
}

.contact-form {
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 1.5em;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 0.7em;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.submit-btn {
  background-color: #007bff;
  color: white;
  padding: 0.7em 2em;
  border: none;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #0056b3;
}

/* Footer */
.footer {
  padding: 1.5em 2em;
  background-color: #333;
  color: white;
  text-align: center;
}

.footer a {
  color: #00bfff;
  text-decoration: none;
  margin-left: 0.5em;
}

.container-main{
  display: block;
  margin: 0.5rem;
}
